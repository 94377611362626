$font-stack: 'Montserrat', sans-serif;
$base-color: #333;

ins, mark {
  color: #000;
  dfn, mark {
    font-style: italic;
  }
}

b, mark, strong {
  font-weight: 700;
}

body, fieldset, figure, form {
  margin: 0;
}

fieldset, hr, img, legend {
  border: 0;
}

.visuallyhidden, hr {
  height: 1px;
  padding: 0;
}

fieldset, hr, legend {
  padding: 0;
}

.hidden, .invisible {
  visibility: hidden;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

[hidden], audio:not([controls]) {
  display: none;
}

.ir, hr {
  display: block;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-size: 13px;
  line-height: 1.231;
  font-family: $font-stack;
  color: $base-color;
}

a:active, a:focus, a:hover, button:active, button:focus, button:hover {
  outline: 0 !important;
}

abbr[title] {
  border-bottom: 1px dotted;
}

blockquote {
  margin: 1em 40px;
}

hr {
  border-top: 1px solid #ccc;
  margin: 1em 0;
}

ins {
  background: #ff9;
  text-decoration: none;
}

mark {
  background: #ff0;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
  &:after, &:before {
    content: "";
    content: none;
  }
}

small {
  font-size: 85%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  sup {
    top: -.5em;
  }
  sub {
    bottom: -.25em;
  }
}

img {
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

td, textarea {
  vertical-align: top;
}

button, input {
  line-height: normal;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

.ir, .visuallyhidden {
  border: 0;
  overflow: hidden;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ir {
  text-indent: -999em;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
  br {
    display: none;
  }
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  margin: -1px;
  position: absolute;
  width: 1px;
  &.focusable:active, &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

.clearfix:after, .clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  outline: none;
}

html, body {
  height: 100%;
}

html {}

body {
  overflow-x: hidden;
  transition: .5s linear;
  background: #fff;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4;
  font-family: $font-stack;
  color: $base-color;
}

img, object, embed, video {
  max-width: 100%;
  border: none;
}

a {
  transition: .5s linear;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    transition: .5s linear;
  }
}

.clear, .clr {
  clear: both;
  height: 1px;
  overflow: auto;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
  padding: 0;
}

.dis-none {
  display: none;
  transition: 1s ease;
}
