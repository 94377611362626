.table {
  :global .rs-pagination-group {
    margin: 0 auto;
    padding: 16px 0;
    justify-content: center;
    .rs-pagination {
      display: flex;
      button {
        display: flex;
        font-size: 14px;
        color: #74788c;
        font-weight: 600;
        line-height: 18px;
        &.rs-pagination-btn-active {
          background: #4527af;
          color: #fff;
          border-radius: 50%;
          width: 35px;
          height: 35px;
        }
      }
    }
  }
  .header {
    color: #000;
    :global .rs-table-cell {
      border-bottom: 1px solid #e2e3e7;
      .rs-table-cell-content {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: #fff;
        text-transform: uppercase;
        //padding: 2px;
        background-color: #4527af;
      }
      .rs-table-cell-header-icon-sort{
        color: #ffffff;
      }
    }
  }
  .row {
    border-bottom: 1px solid #e2e3e7;
    :global .rs-table-cell-content {
      padding: 0 10px;
      display: flex;
      align-items: center;
      .rs-table-cell-wrap {
        font-size: 16px;
        color: #74788c;
        font-weight: 500;
        line-height: 24px;
        div{
          button{
          padding: 20px;
          background: unset;
          }
        }
        span{
          button{
          background: unset;
          }
        }
      }
      .rs-table-cell-wrap{
        div{
          button{
            padding: 2px 31px 2px 0px;
          }
        }
      }
    }
  }
}
.toolTip{
  padding: 10px;
}

