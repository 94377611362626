ins,
mark {
    color: #000;
}

dfn,
mark {
    font-style: italic;
}

b,
mark,
strong {
    font-weight: 700;
}

body,
fieldset,
figure,
form {
    margin: 0;
}

fieldset,
hr,
img,
legend {
    border: 0;
}

.visuallyhidden,
hr {
    height: 1px;
    padding: 0;
}

fieldset,
hr,
legend {
    padding: 0;
}

.hidden,
.invisible {
    visibility: hidden;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

[hidden],
audio:not([controls]) {
    display: none;
}

.ir,
hr {
    display: block;
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body,
button,
input,
select,
textarea {
    font-family: sans-serif;
    color: #222;
}

a:active,
a:focus,
a:hover,
button:active,
button:focus,
button:hover {
    outline: 0 !important;
}

abbr[title] {
    border-bottom: 1px dotted;
}

blockquote {
    margin: 1em 40px;
}

hr {
    border-top: 1px solid #ccc;
    margin: 1em 0;
}

ins {
    background: #ff9;
    text-decoration: none;
}

mark {
    background: #ff0;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

q {
    quotes: none;
}

q:after,
q:before {
    content: "";
    content: none;
}

small {
    font-size: 85%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}

img {
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}

svg:not(:root) {
    overflow: hidden;
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
}

td,
textarea {
    vertical-align: top;
}

button,
input {
    line-height: normal;
}

button,
input[type=button],
input[type=reset],
input[type=submit] {
    cursor: pointer;
    -webkit-appearance: button;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
}

input[type=search] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
    resize: vertical;
}

.ir,
.visuallyhidden {
    border: 0;
    overflow: hidden;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.ir {
    text-indent: -999em;
    background-color: transparent;
    background-repeat: no-repeat;
    text-align: left;
    direction: ltr;
}

.ir br {
    display: none;
}

.hidden {
    display: none !important;
}

.visuallyhidden {
    clip: rect(0 0 0 0);
    margin: -1px;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.clearfix:after,
.clearfix:before {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    outline: none;
}

html,
body {
    height: 100%;
}

html {}

body {
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
    transition: .5s linear;
    color: #333;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.4;
    background: #f0f0f0;
}

img,
object,
embed,
video {
    max-width: 100%;
    border: none;
}

a {
    transition: .5s linear;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    transition: .5s linear;
}

.clear,
.clr {
    clear: both;
    height: 1px;
    overflow: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000;
    font-weight: 300;
    margin: 0;
    padding: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

p {
    margin: 0;
    padding: 0;
}

.dis-none {
    display: none;
    transition: 1s ease;
}

.input::-webkit-input-placeholder {
    color: #666;
    font-weight: 400 !important;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.input::-moz-placeholder {
    color: #666;
    font-weight: 400 !important;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.input:-moz-placeholder {
    color: #666;
    font-weight: 400 !important;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.input:focus::-webkit-input-placeholder {
    color: transparent;
}

.input:focus::-moz-placeholder {
    color: transparent;
}

.input:-moz-placeholder {
    color: transparent;
}


.relative {
    position: relative
}

.align-right {
    text-align: right
}

.align-center {
    text-align: center
}

.b {
    color: #191734
}

.e {
    color: #a53600
}

.red {
    color: red;
    font-style: normal;
    font-weight: 500;
    font-size: 18px
}


.container {
    width: 98%;
    margin: auto;
}

.grid {
    width: 100%;
    margin-left: auto;
    margin-right: auto
}

.grid [class*=col] {
    width: 100%;
    margin-left: 0
}

.login-container {
    margin: 0 auto 0 auto
}

.login-container .left-col {
    background: #1C1D21;
    border-radius: 25px 25px 0 0;
    position: relative;
    padding-bottom: 3rem
}

.left-col .align-center {
    padding: 2rem
}

.left-col .login {
    color: #fff;
    width: 300px;
    margin: auto
}

.left-col .login h2 {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    padding: 0 0 1rem 0
}

.left-col .login p {
    margin: 0;
    padding: 0 0 10px 0
}

.left-col .name {
    background: none;
    border: none;
    outline: none;
    border-bottom: 1px solid #77777a;
    padding: 5px 0;
    width: 100%;
    transition: .5s linear;
    color: #fff;
    margin: 1rem 0;
    border-radius: 0
}

.left-col .name:hover {
    border-color: #fff
}

.left-col .submit {
    background: #A4A4F4;
    border: none;
    outline: none;
    padding: 10px 0;
    text-align: center;
    color: #fff;
    font-size: 1;
    width: 100%;
    border-radius: 6px;
    transition: .5s linear;
}

.left-col .submit:hover {
    background: #9393FF;
}

.login-container .right-col {
    height: 50vh;
    background: url(../../assets/images/login-right-bg.png) right bottom no-repeat #a4a4f4;
    border-radius: 0 0 25px 25px;
    background-size: contain;
}

.login-container .right-col h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
    line-height: .95;
    padding: 2rem 1rem 1rem 1rem;
}

.login-container .right-col h1 span {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: normal
}

.login-container .right-col h1 span br {
    display: none
}

.login-container .right-col img {
    width: 100%;
    height: 100%
}

.header {
    border-bottom: 1px solid #f5f5f5;
    padding: 1rem
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.header ul li {
    display: inline-block;
    vertical-align: top
}

.header ul li.li-first {
    width: 200px;
}

.header ul li.li-second {
    text-align: right;
    width: calc(100% - 205px)
}

.header ul li.li-third {
    width: 100%
}

.header ul li.li-fourth {
    width: 45%
}

.header ul li.li-fifth {
    width: 53%;
    text-align: right
}

.header .logo {
    position: relative;
    padding-right: 1.5rem;
}

.header .logo:after {
    content: '';
    position: absolute;
    right: 0;
    top: -16px;
    width: 1px;
    height: 55px;
    background: #f5f5f5;
}

.header img {
    vertical-align: middle
}

.header h1 {
    font-weight: 500;
    line-height: normal;
    padding: .75rem 0
}

.header .admin {
    padding-top: 0
}

.header .admin img {
    height: auto;
    width: auto;
    margin-right: 10px
}

.header .col1 {
    text-align: center
}

.header .col1 img {
    margin-top: 12px
}

.box-shadow {
    background: #fff;
    box-shadow: 0 1px 10px 0 rgba(75, 75, 75, .3);
}

.border-radius {
    border-radius: 15px
}

.title {
    background: #a4a4f4;
    color: #fff;
    padding: 0.25rem 3rem 0.25rem 0.5rem;
    margin: 1rem 0 1px 0;
    border-radius: 0 50px 0 0;
    display: inline-block;
    font-weight: 400;
    font-size: 1.7rem;
}

.align-right.add {
    margin: 1rem 1rem 0 0
}

.dashboard-table {
    padding: 0 1rem 1rem 1rem
}

.right-space {
    margin-bottom: 1rem
}


.tab {
    margin: 1rem 0 0 0rem;
    padding: 0
}

.tab li {
    list-style: none;
    display: inline-block;
    vertical-align: bottom;
    padding-left: 4px;
}

.tab li a {
    display: block;
    padding: 8px 20px;
    background: #9393ff82;
    border-radius: 10px 10px 0 0;
    color: #fff
}

.tab li a.current {
    background: #4527af;
    padding-top: 14px;
    box-shadow: 0 1px 10px 0 rgba(75, 75, 75, .3);
}




.employee {
    padding: 0 1rem 1rem 1rem;
    background: #f8f8f8;
}

.edit-bg {
    background: #f1f1f1;
    border-radius: 0 0 10px 10px;
    padding: 10px 1rem;
    margin-bottom: 1.25rem
}

.employee .col3 {
    margin-left: 0 !important
}

.employee .col3 .box-shadow {
    padding: 1rem 0;
    position: relative;
    margin-bottom: 1.5rem
}

.employee .col3 .box-shadow h2 {
    color: #ef9b0f;
    font-weight: 500;
    background: url(../../assets/images/line-bg.png) left center repeat-x;
    padding: 0;
    margin: .5rem 1.25rem 0 1.25rem;
    font-size: 1.25rem
}

.employee .col3 .box-shadow span {
    background: #fff;
    display: inline-block;
    padding-right: 10px
}

.employee .col3 .box-shadow:after {
    content: " ";
    display: block;
    position: absolute;
    right: 1rem;
    top: 1.65rem;
    width: 20px;
    height: 20px;
    background: url(../../assets/images/favicon.svg) center center no-repeat;
    z-index: 1
}

.employee .col3 .box-shadow ul {
    border-top: 1px solid #f4f4f4;
    margin: 1rem 1.25rem 1.5rem 1.25rem;
    padding: 1rem 0 0 0;
    list-style: none
}

.employee .col3 .box-shadow ul li {
    padding-bottom: .5rem
}

.employee .col3 .box-shadow ul li strong {
    display: inline-block;
    vertical-align: top;
    width: 125px;
    font-weight: 600
}

.employee .col3 .box-shadow ul li span {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 140px);
}

.employee .col3 .box-shadow .align-right {
    margin-right: 1.25rem
}

.employee .col9 {}

.employee .col9 .box-shadow {
    padding: 1.5rem 1.25rem 1.25rem 1.25rem;
    position: relative;
}

fieldset {
    border: 1px solid #f4f4f4;
    border-radius: 6px;
    padding: 1rem
}

fieldset legend {
    color: #9393ff;
    font-weight: 500;
    font-size: 1.5rem
}

fieldset h3 {
    font-size: 1.25rem;
    font-weight: 500;
    padding-bottom: 1.25rem
}

.two-col {
    margin: 0;
    padding: 0;
}

.two-col li {
    padding-bottom: 1rem
}

.two-col li strong {
    font-weight: 600;
    display: block;
    padding-bottom: 10px
}

.two-col li span {
    width: calc(100% - 160px);
}

.required {
    color: red;
    font-style: normal
}

.input,
.form-select {
    background: none;
    border: 1px solid #e5e4e4;
    outline: none;
    padding: 10px;
    width: calc(100% - 20px);
    border-radius: 6px;
    transition: .5s linear;
}

.input:hover,
.input:focus,
.form-select:hover,
.form-select:focus {
    border-color: #9393ff
}

.form-select {
    width: 100%
}

.button {
    background: #A4A4F4;
    border: none;
    outline: none;
    padding: 10px 0;
    text-align: center;
    color: #fff;
    font-size: 1;
    width: 100%;
    border-radius: 6px;
    transition: .5s linear;
}

.button:hover {
    background: #9393FF;
}

.button-block {
    clear: both;
    border-top: 1px solid #e5e4e4;
    text-align: center;
    padding: 1rem;
    margin-top: .5rem
}

.button-block .button {
    width: auto;
    padding: 10px 50px;
    margin: 0 .25rem
}

.search-popup {
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    background-color: #fff;
    padding: 18px 8px;
    z-index: 1;
}

.search-icon {
    cursor: pointer;
    margin-right: 10px;
}

.search-popup.active {
    display: block;
    position: relative;
}

.search-field {
    background-color: transparent;
    background-image: url(../../assets/images/icon-search.svg);
    background-position: 5px center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    border: none;
    cursor: pointer;
    height: 38px;
    margin: 3px 0;
    padding: 0 0 0 34px;
    position: relative;
    -webkit-transition: width 400ms ease, background 400ms ease;
    transition: width 400ms ease, background 400ms ease;
    width: 0px;
    cursor: pointer;
    border: 1px solid #9393ff;
    width: 90%;
    border-radius: 6px;
}

.search-field:focus {
    background-color: #fff;
    border: 1px solid #9393ff;
    cursor: text;
    outline: 0;
    width: 90%;
    color: #222;
    border-radius: 6px;

}

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0 0 0 / 93%);
}

/* Modal Content */
.modal-content {
    background-color: #fff;
    margin: auto;
    padding: 50px 25px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.search-space-top {
    margin-top: 8px;
}

.feed-message {
    text-align: center;
}

.disabledTab {
    background-color: #c5c6c7 !important;
    pointer-events: none;
    color: gray !important;
    display: none;

}

.zoho-search {
    margin-top: 10px;
}

.zoho-search .input {
    width: 65%;
    border-radius: 6px 0px 0px 6px;
}

.zoho-search .zoho-button {
    background: url(../../assets/images/zoho-logo-web.svg) center center no-repeat rgba(3, 226, 98, 0.36);
    width: 75px;
    height: 41.5px;
    vertical-align: top;
    margin-left: 0px;
    border: 1px solid #c5c6c7;
    border-radius: 0px 6px 6px 0px;

}
.calander-button{
background: url(../../assets/images/calendar.svg) center center no-repeat rgba(3, 226, 98, 0.36);
    width: 75px;
    height: 41.5px;
    vertical-align: top;
    margin-left: 0px;
    border: 1px solid #c5c6c7;
    border-radius: 0px 6px 6px 0px;}
.calander-div{
    margin-top: 11px;
    text-align: right;
}


@media (max-width:1200px) and (min-width:769px) {}

@media only screen and (max-width: 600px) {}

@media only screen and (min-width: 600px) {}

/*@media only screen and (max-width: 1366px) and (min-width: 768px) and (min-height: 768px) {*/
@media only screen and (max-width: 1366px) and (min-width: 768px) {}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {}


@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (orientation : landscape) {}

@media only screen and (min-device-width : 414px) and (max-device-width : 896px) and (orientation : landscape) {}


@media only screen and (min-width: 992px) {

    .grid {
        width: 100%
    }

    .grid [class*=col] {
        float: left
    }

    .grid:after,
    .grid:before,
    .grid .newrow:before {
        display: table;
        line-height: 0;
        content: ""
    }

    .grid:after,
    .grid .newrow,
    .grid .newrow:before {
        clear: both
    }

    .grid [class*=col] {
        margin-left: 1.694915254%;
        margin-right: 0
    }

    .grid .newrow,
    .grid [class*=col]:first-of-type {
        margin-left: 0
    }

    .grid .col1 {
        width: 6.779661017%
    }

    .grid .col2 {
        width: 15.25423729%
    }

    .grid .col3 {
        width: 22.72881356%
    }

    .grid .col4 {
        width: 32.20338983%
    }

    .grid .col5 {
        width: 40.6779661%
    }

    .grid .col6 {
        width: 51.15254237%
    }

    .grid .col7 {
        width: 57.62711864%
    }

    .grid .col8 {
        width: 66.10169492%
    }

    .grid .col9 {
        width: 74.57627119%
    }

    .grid .col10 {
        width: 83.05084746%
    }

    .grid .col11 {
        width: 91.52542373%
    }

    .grid .col12 {
        width: 100%
    }

    .grid .interviewCol {
        width: 49.152542%
    }

    .login-container {
        max-width: 1140px;
        margin-top: 2vh;
    }

    .left-col .align-center {
        text-align: right
    }

    .login-container .left-col {
        float: left;
        width: 45%;
        height: 96vh;
        border-radius: 25px 0 0 25px;
        padding-bottom: 0
    }

    /*.left-col .align-right{padding:2rem}*/
    .left-col .login {
        position: absolute;
        transform: translateY(-50%);
        width: 350px;
        top: 50%;
        left: 0;
        right: 0
    }

    .login-container .right-col {
        float: left;
        width: 55%;
        height: 96vh;
        border-radius: 0 25px 25px 0;
        background-size: contain;
    }

    .login-container .right-col h1 {
        font-size: 3rem;
        padding: 2rem 1rem 3rem 4rem;
        line-height: 1
    }

    .login-container .right-col h1 span {
        display: block;
        font-size: 2.75rem;
    }

    .login-container .right-col h1 span br {
        display: block
    }


    .header .logo:after {
        height: 75px;
    }

    .header h1 {
        padding: 0
    }

    .header .admin {
        padding-top: 10px
    }

    .header ul li.li-first {
        width: 25%
    }

    .header ul li.li-third {
        width: 49%
    }

    .header ul li.li-third img {
        width: auto;
        height: 50px;
    }

    .header ul li.li-fifth {
        width: 25%;
        text-align: right
    }

    .li-second img,
    .li-fourth img {
        margin-top: 10px
    }

    .employee .col3 .box-shadow {
        margin: 0
    }

    .two-col li strong {
        display: inline-block;
        vertical-align: top;
        width: 150px;
    }

    .two-col li span {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 160px);
    }

    .right-space {
        margin-right: 1rem;
        margin-bottom: 0
    }

    .left-space {
        margin-left: 1rem
    }


}



@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }

    .grid-height {
        min-height: 75vh;
    }

}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px
    }
}

.footer {
    background: #4527af;
    color: #fff;
    padding: 10px 0;
    text-align: center
}

.invalidInput {
    border: 1px solid red;
}







/* -- Loader -- */
.be-loading-bg {
    position: fixed;
    height: 100%;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    transition: 1s ease;
}

.be-loading {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    background: url(../../assets/images/be-small.png) center center no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.be-loading div {
    animation: be-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px
}

.be-loading div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #0e103d;
    margin: -4px 0 0 -4px
}

.be-loading div:nth-child(1) {
    animation-delay: -.036s
}

.be-loading div:nth-child(1):after {
    top: 63px;
    left: 63px
}

.be-loading div:nth-child(2) {
    animation-delay: -.072s
}

.be-loading div:nth-child(2):after {
    top: 68px;
    left: 56px
}

.be-loading div:nth-child(3) {
    animation-delay: -.108s
}

.be-loading div:nth-child(3):after {
    top: 71px;
    left: 48px
}

.be-loading div:nth-child(4) {
    animation-delay: -.144s
}

.be-loading div:nth-child(4):after {
    top: 72px;
    left: 40px
}

.be-loading div:nth-child(5) {
    animation-delay: -.18s
}

.be-loading div:nth-child(5):after {
    top: 71px;
    left: 32px
}

.be-loading div:nth-child(6) {
    animation-delay: -.216s
}

.be-loading div:nth-child(6):after {
    top: 68px;
    left: 24px
}

.be-loading div:nth-child(7) {
    animation-delay: -.252s
}

.be-loading div:nth-child(7):after {
    top: 63px;
    left: 17px
}

.be-loading div:nth-child(8) {
    animation-delay: -.288s
}

.be-loading div:nth-child(8):after {
    top: 56px;
    left: 12px
}

@keyframes be-loading {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.rejectpopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.rejectpopupContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 53%);
    text-align: center;
}

.interviewpopupContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 53%);
    text-align: center;
    width: 70%;
}

.rejectbutton {
    margin: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #03E262;
    color: #fff;
}

.rejectbutton:hover {
    background-color: #0d6633;
    color: #fff;
}

.rejectcancelbutton {
    margin: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #ef9b0f;
    color: #fff;
}

.rejectcancelbutton:hover {
    background-color: #ef9b0fdb;
    color: #fff;
}

.closeIntervieButton {
    float: right;
}

.progress-stage {
    display: inline-block;
    margin-right: 20px;
    text-align: center;
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
}

.completed {
    background-color: #4caf50;
}



.circle span {
    color: white;
}

.schudleCopy {
    background: #fad423;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
    margin-right: 12px;
}

.successMessage {
    color: green;
    margin-bottom: 10px;
}

.errorMessage {
    color: red;
    margin-bottom: 10px;
}
.custom-tooltip {
    padding: 2px 14px 2px 0px !important;
}
.checkboxList {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;
}

.checkboxColumn {
    display: flex;
    flex-direction: column;
    width: 48%; 
}

.checkboxItem {
    margin-bottom: 10px; 
    text-align: left;
}

.checkboxLabel {
    margin-left: 8px; 
}

.checkboxInput {
    margin-right: 8px; 
}


.toolTipContainer {
    position: relative;
    
}


.custom-tooltip {
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
}


.tooltipText {
    visibility: hidden;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    z-index: 9999;
    bottom: 125%; 
    left: 50%;
    margin-left: -60px; 
    opacity: 0;
    transition: opacity 0.3s;
}


.tooltipText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}


.custom-tooltip:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}
